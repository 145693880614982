import * as React from "react"

import Layout from "../layout/Layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const StorePage = () => (
  <Layout>
    <Seo title="The Juicy Pause Store" />
    <div className="min-h-screen flex flex-col items-center justify-center gap-8 -mt-24">
      <h1 className="mobile-title lg:desktop-title">Coming Soon</h1>
      <p className="mobile-paragraph lg:desktop-paragraph max-w-2xl text-center ">
        We are working hard on the store, filling it with special and exclusive
        content carefully prepared for you!
      </p>
    </div>
    <div className="fixed bottom-0 right-0 scale-75 translate-x-4 lg:translate-x-0 lg:scale-150 ">
      <StaticImage
        height={345}
        src="../images/BackgroundElements/Home/Mermaid.png"
        alt="Mermaid"
        placeholder="tracedSVG"
      />
    </div>
  </Layout>
)

export default StorePage
